export const queryKey = {
  CURRENCY: "currency",
  USER_INFO: "userInfo",
  HISTORY_DEPOSIT_LIST: "historyDepositList",
  HISTORY_WITHDRAWAL_LIST: "historyWithdrawalList",
  BET_RECORD_GAME_LIST: "recordGameList",
  COMMON_ISSUES: "commonIssues",
  CS_LINK: "customerServiceLink",
  CS_LINK_NO_CURRENCY: "customerServiceLinkNoCurrency",
  BET_RECORD_GAME_TYPES: "betRecordGameTypes",
  BET_RECORD_GAME_LIST_BY_TYPE: "betRecordGameList",
  PROMOTION_TYPE: "promotionType",
  PROMOTION_LIST: "promotionList",
  PROMOTION_DETAIL: "promotionDetail",
  ALL_LANG_LIST: "allLangList",
  LANG_LIST_BY_CURRENCY: "langListByCurrency",
  BANKS: "banks",
  VIRTUAL_CHANNELS: "virtual_channels",
  USER_BANK_ACCOUNT: "userBankAccount",
  USER_VIRTUAL_ACCOUNT: "userVirtualAccount",
  HOME_BANNER: "homeBanner",
  MARQUEE: "marquee",
  TRANSACTION_HISTORY: "transaction_history",
  DEPOSIT_PAYMENT_METHOD: "deposit_payment_method",
  WITHDRAW_PAYMENT_METHOD: "withdraw_payment_method",
  DEPOSIT_PAYMENT_ONLINE_METHOD: "deposit_payment_online_method",
  DEPOSIT_VIRTUAL_CHANNEL_LIST: "deposit_virtual_channel_list",
  DEPOSIT_BANK_CHANNEL_LIST: "deposit_bank_channel_list",
  DEPOSIT_ONLINE_METHOD: "deposit_online_method",
  DEPOSIT_ONLINE_PROVIDER: "deposit_online_provider",
  EXCHANGE_RATE: "exchange_rate",
  GET_EMAIL_VERIFY: "getEmailVerifyCode",
  ANNOUNCE: "noticeList",
  ANNOUNCE_DETAIL: "noticeDetail",
  MESSAGE: "messageList",
  MESSAGE_DETAIL: "messageDetail",
  GAME_CATEGORY: "game_category",
  GAME_PRODUCT: "game_product",
  HOME_GAME_TYPE: "homeGameType",
  HOME_GAME_CATEGORY: "homeGameCategory",
  HOME_GAME_LIST: "homeGameList",
  VIP_USER_LEVEL: "vip_user_level",
  VIP_NEXT_USER_LEVEL: "vip_next_user_level",
  VIP_USER_LEVEL_INFO: "vip_user_level_info",
  VIP_GAME_REBATE: "vip_game_rebate",
  WITHDRAW_CHURN: "withdraw_churn",
  WITHDRAW_FEE: "withdraw_fee",
  USER_WALLET: "user_wallet",
  USER_GAME_WALLET: "user_game_wallet",
  WALLET_GAME_LIST: "wallet_game_list",
  BET_RECORD: "bet_record",
  HOME_PROMOTION_BANNER: "home_promotion_banner",
  USER_AREA_CODE: "user_area_code",
  SETTINGS: "settings",

  WIN_PLATFORM_LIST: "win_platform_list",
  WIN_GAME_LIST: "win_game_list",
  WIN_GAME_CATEGORY: "win_game_category",
  WIN_GAME_WAY: "win_game_way",
  WIN_GAME_METHOD: "win_game_method",
  WIN_GAME_HISTORY: "win_game_history",
  WIN_BINANCE_KLINE: "win_binance_kline",
  WIN_SETTING: "win_setting",
  WIN_GAME_RULE: "win_game_rule",

  HOME_1BBET_WIN_GAME: "home_1bbet_win_game",
  MG_GAME_LINK: "mg_game_link",
  T1_GAME_LINK: "t1_game_link",
  T1_GAME_LIST: "t1_game_list",
  PP_GAME_LINK: "pp_game_link",
  PP_GAME_LIST: "pp_game_list",
  PG_GAME_LINK: "pg_game_link",
  PG_GAME_LIST: "pg_game_list",
  YGG_GAME_LINK: "ygg_game_link",
  YGG_GAME_LIST: "ygg_game_list",
  EVOPLAY_GAME_LINK: "evoplay_game_link",
  EVOPLAY_GAME_LIST: "evoplay_game_list",
  JILI_GAME_LINK: "jili_game_link",
  JILI_GAME_LIST: "jili_game_list",
  SPRIBE_GAME_LINK: "spribe_game_link",
  SPRIBE_GAME_LIST: "spribe_game_list",
  PT_GAME_LINK: "pt_game_link",
  PT_GAME_LIST: "pt_game_list",
  PPLIVE_GAME_LINK: "pplive_game_link",
  PPLIVE_GAME_LIST: "pplive_game_list",
  FC_GAME_LINK: "fc",
  FC_GAME_LIST: "fc_game_list",
  CQ9_GAME_LINK: "cq9_game_link",
  CQ9_GAME_LIST: "cq9_game_list",
  PLAYSTAR_GAME_LINK: "playstar_game_link",
  PLAYSTAR_GAME_LIST: "playstar_game_list",
  WELIVE_GAME_LINK: "welive_game_link",
  WELIVE_GAME_LIST: "welive_game_list",
  WEBLOCKCHAIN_GAME_LINK: "weblockchain_game_link",
  WEBLOCKCHAIN_GAME_LIST: "weblockchain_game_list",
  WELOTTERY_GAME_LINK: "welottery_game_link",
  WELOTTERY_GAME_LIST: "welottery_game_list",
  TCG_GAME_LINK: "tcg_game_link",
  TCG_GAME_LIST: "tcg_game_list",
  YOUBET_GAME_LINK: "youbet_game_link",
  YOUBET_GAME_LIST: "youbet_game_list",
  AESEXY_GAME_LINK: "aesexy_game_link",
  AESEXY_GAME_LIST: "aesexy_game_list",
  SEARCH_GAME: "search_game",
  HOT_GAME: "hot_game",

  LOTTERY_GAME_RECORD: "lottery_game_record",
  CURRENT_LOTTERY: "current_lottery",
  GAME_WAY: "game_way",
  PROMO_EVENT: "promo_event",
  DEPOSIT_CHURN: "deposit_churn",
  STATISTICS: "statistics",
  PRIZE_LIST: "prize_list",
  SPIN_RECORD: "spin_record",
  SHOW_BUBBLE: "show_bubble",
};

export default queryKey;
