import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { getGameMethod, getGameRecord, getGameRule, getGameWay } from "./service";

export const useGetGameWay = ({ params, options }) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useQuery(
    [queryKey.WIN_GAME_WAY, params, ..._arrParams],
    () =>
      getGameWay({ params: params }).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameMethod = ({ params, options }) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useQuery(
    [queryKey.WIN_GAME_METHOD, params, ..._arrParams],
    () =>
      getGameMethod({ params: params }).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};

export const useGetGameHistory = ({ params, options }) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });
  const _arrParams = Object.keys(params).map((key) => params[key]);
  return useQuery(
    [queryKey.WIN_GAME_HISTORY, ..._arrParams],
    () =>
      getGameRecord({ params }).then((res) => {
        const _result = res.data.data;
        return _result;
      }),
    {
      ...options,
    },
  );
};
export const useGetGameFeeAndOdds = ({ options }) => {
  return useQuery(
    [queryKey.WIN_GAME_RULE],
    () =>
      getGameRule({}).then((res) => {
        const _result = res.data.data;
        const finalData = {};
        // console.log('_result', _result)
        if (!!_result) {
          finalData["fee"] = _result?.fee;
          _result?.odds?.map((oddsSetting) => {
            // console.log('oddsSetting', oddsSetting)
            finalData[oddsSetting.game_type] = {};
            return oddsSetting?.game_method?.map((gameContent) => {
              return gameContent.option?.map(
                (option) => (finalData[oddsSetting.game_type][option.key] = option.odds),
              );
            });
          });
        }
        // console.log("finalData", finalData);
        return finalData;
      }),
    {
      ...options,
    },
  );
};
