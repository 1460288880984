import "./style.scss";
const Background = (props) => {
  return (
    <div
      style={{
        background: "radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)",
        width: "100vw",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      {props.children}
    </div>
  );
};
export default Background;
