import { Spin } from "antd";
import { Grid } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import { useGetGameMethod } from "../../hooks/winBet/wingame.hooks";
import styles from "./style.module.scss";

const WinBetPanel = ({
  onBetItemClick = () => {},
  gameWay = null,
  betSelectionEnum = {},
  showOdds = false,
}) => {
  const { t } = useTranslation();
  const {
    data: gameMethod,
    isLoading: gameMethodLoading,
    isError: gameMethodError,
  } = useGetGameMethod({
    params: {
      game_way_type: gameWay ? gameWay[0]?.game_way_type : "",
    },
    options: {
      enabled: !!gameWay,
    },
  });

  const { currentPeriod } = useGameWebsocket();

  const renderOdds = (option) => {
    if (showOdds === false) return;
    return <div className={styles.odds}>{option.odds}</div>;
  };

  const renderBetPanel = () => {
    if (!gameMethod) return null;
    if (!currentPeriod.period) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.title}>{t("wingame.loading")}</div>
          <Spin />
        </div>
      );
    }
    return gameMethod.game_method.map((item) => {
      const setting = betSelectionEnum[item.code];
      return (
        <Grid className={styles.grid} columns={setting.column} key={item.code} gap={12}>
          {item.option.map((option) => {
            return (
              <Grid.Item key={option.key}>
                <div
                  onClick={() =>
                    onBetItemClick({
                      ...option,
                      ...setting[option.key],
                      game_method_code: item.code,
                    })
                  }
                  className={styles.betItem}
                  style={
                    setting[option.key].color.length <= 1
                      ? { backgroundColor: `${setting[option.key].color[0]}` }
                      : {
                          backgroundImage: `linear-gradient(160deg,${
                            setting[option.key].color[0]
                          } 50%, ${setting[option.key].color[1]} 0)`,
                        }
                  }
                >
                  {t(
                    `${
                      setting[option.key].translate
                        ? t(setting[option.key].title)
                        : setting[option.key].title
                    }`,
                  )}
                  {renderOdds(option)}
                </div>
              </Grid.Item>
            );
          })}
        </Grid>
      );
    });
  };

  return <div className={styles.container}>{renderBetPanel()}</div>;
};

export default WinBetPanel;
