import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "../index.js";
import dayjs from "dayjs";

function DateButtonGroup({ dateOptions, chosenDate, setChosenDate, setDate }) {
  const { t } = useTranslation();
  const onChange = (e) => {
    const format = "YYYY-MM-DD";
    let date = [];
    const type = e.target.value;
    switch (type) {
      case "today":
        date = [dayjs().format(format), dayjs().format(format)];
        break;
      case "yesterday":
        date = [
          dayjs().add(-1, "d").format(format),
          dayjs().add(-1, "d").format(format),
        ];
        break;
      case "sevenDays":
        date = [dayjs().add(-7, "d").format(format), dayjs().format(format)];
        break;
      case "fifteenDays":
        date = [dayjs().add(-15, "d").format(format), dayjs().format(format)];
        break;
      case "thirtyDays":
        date = [dayjs().add(-30, "d").format(format), dayjs().format(format)];
        break;
      default:
        date = [dayjs().format(format), dayjs().format(format)];
        break;
    }
    setChosenDate(type);
    setDate(date);
    console.log("date", date);
  };
  return (
    <ButtonGroup
      options={dateOptions}
      chosenVal={chosenDate}
      setChosenVal={setChosenDate}
      amountInOneRow="three"
      buttonOnChange={onChange}
    />
  );
}

export default DateButtonGroup;
