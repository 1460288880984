import { useQueryClient } from "@tanstack/react-query";
import { Grid } from "antd-mobile";
import { GridItem } from "antd-mobile/es/components/grid/grid";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import queryKey from "../../enumerations/queryKey";
import webSocketActionEnum from "../../enumerations/webSocketActionEnum";
import { useGetGameHistory } from "../../hooks/winBet/wingame.hooks";
import styles from "./style.module.scss";

const HistoryPanel = ({
  selectedGameList = undefined,
  gameCategoryDetailType = undefined,
  style = {},
  titleRender,
  refetchWaitTime = 5,
  leading = true,
  trailing = false,
}) => {
  const COLOR_MAPING = {
    LOTWIN: {
      red: "#FF464E",
      green: "#20AC4A",
      violet: "#5C32A8",
    },
    LOTBIT: {
      big: "#20AC4A",
      small: "#B2533F",
      odd: "#5C32A8",
      even: "#A7714D",
      small_even: "#C98C6F",
      small_odd: "#367C7C",
      big_even: "#A0864A",
      big_odd: "#C89E90",
    },
    LOTLAUNCH: {
      red: "#FF464E",
      green: "#20AC4A",
      violet: "#5C32A8",
    },
    LOTGOPHERS: {
      red: "#FF464E",
      green: "#20AC4A",
      violet: "#5C32A8",
    },
    LOTFEDERAL: {
      red: "#FF464E",
      green: "#20AC4A",
      violet: "#5C32A8",
    },
  };
  const PAGE_SIZE = 10;
  const TOTAL_PAGE = 100;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const timer = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(100);
  const { refetchData, prevPeriod } = useGameWebsocket();
  const { data, isLoading, isError, refetch } = useGetGameHistory({
    params: {
      game_type: selectedGameList,
      page_size: PAGE_SIZE,
      page: currentPage,
    },
    options: {
      enabled: !!selectedGameList,
    },
  });

  const numberColor = (colorList) => {
    const colors = colorList.split(",").filter((_color) => _color !== "violet");
    return {
      color: COLOR_MAPING[gameCategoryDetailType][colors[colors.length - 1]],
    };
  };

  const renderColorItems = (colorList) => {
    const colors = colorList.split(",");
    return (
      <div className={styles.colorContainer}>
        {colors.map((color, index) => {
          return (
            <div
              key={`${color}_${index}`}
              className={styles.colorDot}
              style={{ backgroundColor: COLOR_MAPING[gameCategoryDetailType][color] }}
            />
          );
        })}
      </div>
    );
  };

  const renderLaunchColorItems = (colorList) => {
    const colors = colorList.split(",");
    return (
      <div className={styles.colorContainer}>
        {colors ? (
          colors.length === 2 ? (
            <div
              className={styles.colorDot}
              style={{
                backgroundImage: `linear-gradient(160deg,${
                  COLOR_MAPING[gameCategoryDetailType][colors[0]]
                } 50%, ${COLOR_MAPING[gameCategoryDetailType][colors[1]]} 0px)`,
              }}
            />
          ) : (
            colors.map((color, index) => {
              return (
                <div
                  key={`${color}_${index}`}
                  className={styles.colorDot}
                  style={{ backgroundColor: COLOR_MAPING[gameCategoryDetailType][color] }}
                />
              );
            })
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderBitItems = ({ itemList = [] }) => {
    return (
      <Grid columns={3} gap={0} className={styles.bitHistoryItemContainer}>
        {itemList.map((item, index) => {
          return (
            <GridItem
              key={`${item}_${index}`}
              className={styles.bitHistoryItem}
              style={{ backgroundColor: `${COLOR_MAPING[gameCategoryDetailType][item]}` }}
            >
              {t(`win.bitgame.bettype.${item}`)}
            </GridItem>
          );
        })}
      </Grid>
    );
  };

  const renderLotBitItem = (_history, index) => {
    let lastThreeDigits = "";
    let sum = 0;
    if (_history.lottery_number) {
      const arr = [..._history.lottery_number.replace(".", "")].slice(-3);
      sum = arr.reduce((acc, val) => {
        return acc + parseInt(val);
      }, 0);
      lastThreeDigits = arr.join("+");
    }
    return (
      <div className={`${styles.tableRow} ${styles.body}`} key={`${_history.id}_${index}`}>
        <div className={styles.period}>{_history.period}</div>
        {_history.action === webSocketActionEnum.PENDING ? (
          <div className={styles.pending}>{t("wingame.pending")}</div>
        ) : (
          <>
            <div className={styles.number}>{_history.lottery_number || "-"}</div>
            <div className={styles.result}>
              {_history?.lottery_number ? (
                <div className={styles.numberContainer}>
                  <span className={styles.numbers}>{lastThreeDigits}</span>=
                  <span className={styles.numberResult}>{sum}</span>
                </div>
              ) : (
                "-"
              )}
              {renderBitItems({ itemList: _history?.data?.result?.split(",") || [] })}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderHistoryItem = (_history, index) => {
    switch (gameCategoryDetailType) {
      case "LOTWIN":
        return (
          <div className={`${styles.tableRow} ${styles.body}`} key={`${_history.id}_${index}`}>
            <div className={styles.period}>{_history.period}</div>
            {_history.action && _history.action === webSocketActionEnum.PENDING ? (
              <div className={styles.pending}>{t("wingame.pending")}</div>
            ) : (
              <>
                <div className={styles.number} style={numberColor(_history.data.color)}>
                  {_history.lottery_number}
                </div>
                <div className={styles.result}>{renderColorItems(_history.data.color)}</div>
              </>
            )}
          </div>
        );
      case "LOTBIT":
        return renderLotBitItem(_history, index);
      case "LOTFEDERAL":
      case "LOTLAUNCH":
      case "LOTGOPHERS":
        return (
          <div className={`${styles.tableRow} ${styles.body}`} key={`${_history.id}_${index}`}>
            <div className={styles.period}>{_history.period}</div>
            {_history.action && _history.action === webSocketActionEnum.PENDING ? (
              <div className={styles.pending}>{t("wingame.pending")}</div>
            ) : (
              <>
                <div className={styles.number} style={numberColor(_history.data.color)}>
                  {_history.lottery_number}
                </div>
                <div className={styles.result}>{renderLaunchColorItems(_history.data.color)}</div>
              </>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const onPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevState) => {
        return prevState - 1;
      });
    }
  };

  const onNextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage((prevState) => {
        return prevState + 1;
      });
    }
  };

  useEffect(() => {
    if (data) {
      setTotalPage(Math.min(data.last_page, TOTAL_PAGE));
    }
  }, [data]);

  const throttleRefetch = useCallback(
    _.throttle(
      () => {
        refetch();
      },
      [refetchWaitTime * 1000],
      { leading: leading, trailing: trailing },
    ),
    [refetchWaitTime, leading, trailing],
  );

  const appendNewData = ({ newData }) => {
    let id = 0;
    const currentData = queryClient.getQueryData([
      queryKey.WIN_GAME_HISTORY,
      selectedGameList,
      PAGE_SIZE,
      1,
    ]);
    if (currentData?.data && currentData?.data.length > 0 && newData?.period) {
      if (currentData.data[0].period !== newData.period) {
        if (newData.action === webSocketActionEnum.PENDING) {
          id = currentData.data[0].id;
          queryClient.setQueryData(
            [queryKey.WIN_GAME_HISTORY, selectedGameList, PAGE_SIZE, 1],
            (oldData) => {
              return {
                ...oldData,
                data: [
                  {
                    id: id,
                    game_type: newData.channel,
                    period: newData.period,
                    lottery_number: newData.lottery_number,
                    data: newData.data,
                    draw_time: newData.end,
                    created_at: newData.start,
                    updated_at: newData.current_date_time,
                    action: newData.action,
                  },
                  ...oldData.data,
                ],
              };
            },
          );
        }
      } else {
        if (
          currentData.data[0].action &&
          currentData.data[0].action === webSocketActionEnum.PENDING &&
          newData.action === webSocketActionEnum.END
        ) {
          setTimeout(() => {
            throttleRefetch();
          }, refetchWaitTime * 1000);
        }
      }
    }
  };

  useEffect(() => {
    appendNewData({ newData: prevPeriod });
  }, [prevPeriod]);

  // useEffect(() => {
  //   return () => {
  //     if (timer.current) clearTimeout(timer.current);
  //   };
  // }, []);

  return (
    <div className={styles.container} style={{ ...style }}>
      <div className={styles.title}>
        {titleRender ? titleRender() : t("win.historypanel.title")}
      </div>
      <div className={styles.tableRow}>
        <div className={styles.period}>{t("win.historypanel.period")}</div>
        <div className={styles.number}>
          {t(`win.historypanel.number.${gameCategoryDetailType}`)}
        </div>
        <div className={styles.result}>{t("win.historypanel.result")}</div>
      </div>
      {isLoading
        ? ""
        : isError
        ? ""
        : data.data.map((_history, index) => {
            return renderHistoryItem(_history, index);
          })}
      {/* <div className={styles.footer}>
        <div
          className={`${styles.arrow} ${currentPage === 1 ? styles.disabled : ""}`}
          onClick={onPrevPage}
        >
          <LeftOutlined />
        </div>
        <div className={styles.pageContainer}>
          <span className={styles.currentPage}>{currentPage}</span>/{totalPage}
        </div>
        <div
          className={`${styles.arrow} ${currentPage === totalPage ? styles.disabled : ""}`}
          onClick={onNextPage}
        >
          <RightOutlined />
        </div>
      </div> */}
    </div>
  );
};

export default HistoryPanel;
