import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGameWebsocket } from "../../context/WebsocketGameContext";
import winStateEnum from "../../enumerations/winStateEnum";
import styles from "./style.module.scss";

const HOUR_SECOND = 60 * 60;
const MINUTE_SECOND = 60;

const Countdown = ({
  punctuationStyle = {},
  disableTimeZoneTrans,
  disableShowModal,
  setCurrentGameState = () => {},
  currentGameState,
}) => {
  const { t } = useTranslation();
  const { isWaitPeriod, currentPeriod, setCounter } = useGameWebsocket();

  const countdown = useCallback(() => {
    if (currentPeriod.end && currentPeriod.current_date_time) {
      const timeStringProcess = (timeString) => {
        const regex = /\-/g;
        return timeString.replace(regex, "/");
      };
      const difference = dayjs(
        `${timeStringProcess(currentPeriod.end)}${disableTimeZoneTrans ? "" : " +00:00"}`,
      ).diff(dayjs(timeStringProcess(currentPeriod.current_date_time)), "s");

      if (difference <= 5) {
        if (currentGameState !== winStateEnum.DISABLE_BET_PHASE) {
          setCurrentGameState(winStateEnum.DISABLE_BET_PHASE);
        }
      } else {
        if (currentGameState !== winStateEnum.BET_PHASE) {
          setCurrentGameState(winStateEnum.BET_PHASE);
        }
      }

      setCounter(difference);
      return difference;
    }
    return 0;
  }, [currentPeriod.end, currentPeriod.current_date_time]);

  const timer = useCallback(() => {
    const _timer = countdown();
    const hours = String(parseInt(_timer / HOUR_SECOND + 100)).slice(-2);
    const minutes = String(parseInt(((_timer / MINUTE_SECOND) % MINUTE_SECOND) + 100)).slice(-2);
    const seconds = String(parseInt((_timer % MINUTE_SECOND) + 100)).slice(-2);

    return { hours, minutes, seconds };
  }, [countdown]);

  const render = useMemo(() => {
    return (
      <div className={styles.countdown}>
        <div className={styles.countdownNumbers}>
          <div className={styles.countdownBox}>{isWaitPeriod === true ? " " : timer().hours}</div>
          <div style={punctuationStyle} className={styles.punctuation}>
            :
          </div>
          <div className={styles.countdownBox}>{isWaitPeriod === true ? " " : timer().minutes}</div>
          <div style={punctuationStyle} className={styles.punctuation}>
            :
          </div>
          <div className={styles.countdownBox}>{isWaitPeriod === true ? " " : timer().seconds}</div>
        </div>
      </div>
    );
  }, [timer]);
  return render;
};

export default Countdown;
