import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "../../components";
import localStorageKey from "../../enumerations/localStorageKey";
import queryKey from "../../enumerations/queryKey";
import { apiGetUserInfo, apiLogout } from "./service";

export const useGetUserInfo = (options) => {
  return useQuery([queryKey.USER_INFO], () => apiGetUserInfo().then((res) => res.data.data), {
    ...options,
  });
};
export const useLogout = () => {
  return useMutation(() => apiLogout().then((res) => res.data.data), {
    onSuccess: () => {
      window.localStorage.removeItem(localStorageKey.TOKEN);
      window.localStorage.removeItem(localStorageKey.USER_INFO);
      window.localStorage.removeItem(localStorageKey.RECENT_PLAY);
      window.localStorage.removeItem(localStorageKey.SKIP_TUTORIAL);
      setTimeout(() => {
        window.location.href = "/signin";
      }, 1000);
    },
    onError: (error) => {
      toast({ content: error, type: "error" });
    },
  });
};
