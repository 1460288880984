import { Button, Drawer, Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomInput from "../CustomInput/index.jsx";
import { AppBar } from "../index.js";
import {
  useGetUserForgetSendVerify,
  usePostEmailSend,
  usePostEmailVerify,
  usePutForgetPassword,
} from "./hooks/bind.hook";
const COOLDOWN_SEC = 60;
let cooldownNumber;
let cooldownInterval;
function BindEmailPopup({
  visible,
  setVisible,
  onBeforeFinish,
  isForgetPassword = false,
  account,
  title = null,
}) {
  const { mutate: emailSend } = usePostEmailSend();
  const { mutate: forgetEmailSend } = useGetUserForgetSendVerify();
  const { mutate: emailVerify } = usePostEmailVerify({
    onSuccess: onBeforeFinish,
  });
  const { mutate: forgetPassword } = usePutForgetPassword({
    onSuccess: onBeforeFinish,
  });
  const [cooldown, setCooldown] = useState();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const initCoolDown = () => {
    setCooldown(COOLDOWN_SEC);
    cooldownNumber = COOLDOWN_SEC;
    cooldownInterval = setInterval(() => {
      setCooldown(cooldownNumber - 1);
      cooldownNumber = cooldownNumber - 1;
      if (cooldownNumber <= 0) {
        setCooldown(undefined);
        cooldownNumber = undefined;
        clearInterval(cooldownInterval);
      }
    }, 1000);
  };

  const onClose = () => {
    setVisible(false);
  };
  const getCode = async () => {
    try {
      await form.validateFields(["email"]);
      const params = { email: form.getFieldValue("email") };
      if (isForgetPassword) {
        forgetEmailSend({
          ...params,
          type: "email",
          account,
        });
      } else {
        emailSend(params);
      }
    } catch (err) {}
  };
  const onFinish = async (form) => {
    if (isForgetPassword) {
      forgetPassword({ ...form, type: "email", account });
    } else {
      emailVerify(form);
    }
  };

  const renderTitle = () => {
    if (isForgetPassword === true) return null;
    return (
      <div
        style={{
          color: "var(--color-text-main)",
          fontSize: "var(--fontSetting-huge)",
        }}
      >
        {t("normal.bindEmail")}
      </div>
    );
  };

  const renderEmailInput = () => {
    if (isForgetPassword === true) return null;
    return (
      <CustomInput
        name="email"
        label={t("normal.email")}
        placeholder={t("form.holder.pleaseEnter")}
        rules={[
          {
            type: "email",
            message: t("signup.emailFormatError"),
          },
          {
            required: true,
            message: t("form.required", { name: t("normal.email") }),
          },
        ]}
      />
    );
  };

  const renderForgetPasswordForm = () => {
    if (isForgetPassword === false) return null;
    return (
      <>
        <CustomInput
          name="new_password"
          label={t("signup.newPassword")}
          placeholder={t("form.holder.pleaseEnter")}
          password={true}
          autoComplete={"false"}
          rules={[
            {
              required: true,
              message: t("form.required", { name: t("signup.newPassword") }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.newPassword"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <CustomInput
          name="confirm_new_password"
          label={t("signup.confirmNewPassword")}
          dependencies={["password"]}
          placeholder={t("form.holder.pleaseEnter")}
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.confirmNewPassword"),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t("signup.confirmPasswordError")));
              },
            }),
          ]}
        />
      </>
    );
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      destroyOnClose={true}
      visible={visible}
      bodyStyle={{ padding: "0" }}
      width={"100%"}
      style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
    >
      <AppBar
        title={title ? title : t("normal.bindEmail")}
        onClickEvent={() => {
          setVisible(false);
        }}
      />
      <div style={{ padding: 16 }}>
        {renderTitle()}
        <Form
          form={form}
          name="resetPassword"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
        >
          {renderEmailInput()}
          <CustomInput
            name="code"
            label={t("forgot.verifyCode")}
            placeholder={t("form.holder.pleaseEnter")}
            addonAfter={
              <div
                style={{
                  color:
                    cooldown === undefined
                      ? "var(--color-login-button-text-active)"
                      : "var(--color-text-unstable)",
                  fontWeight: 500,
                }}
                onClick={() => {
                  initCoolDown();
                  if (cooldown === undefined) {
                    getCode();
                  }
                }}
              >
                {cooldown === undefined
                  ? t("forgot.sendCode")
                  : t("forgotPassword.cooldown", { sec: cooldown })}
              </div>
            }
            rules={[
              {
                required: true,
                message: t("form.required", {
                  name: t("forgot.verifyCode"),
                }),
              },
            ]}
          />
          {renderForgetPasswordForm()}

          <Button
            block
            size="large"
            style={{ width: "100%", borderRadius: "8px" }}
            type="primary"
            htmlType="submit"
          >
            {t("normal.confirm")}
          </Button>
        </Form>
      </div>
    </Drawer>
  );
}

export default BindEmailPopup;
