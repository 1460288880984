import { SyncOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { useGetUserWallet } from "../../../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../../../plugins/numberFormat";
import styles from "./style.module.scss";

const CURRENCY = localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

const BalanceViewer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { error: walletError, data: walletData, refetch, isFetching } = useGetUserWallet({});

  const onHandleDepositOpen = () => {
    navigate("/exchange?tab=deposit");
  };

  const handleReloadBalance = () => {
    refetch();
  };

  return (
    <div className={styles.balanceContainer}>
      <div className={styles.balanceTitle}>{t("win.normal.balance")}</div>
      <div className={styles.innerContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.balance}>{`${t(
            `currency.symbol.${CURRENCY.toUpperCase()}`,
          )} ${moneyFormat(walletData?.money)}`}</div>
          <div onClick={handleReloadBalance}>
            <SyncOutlined className={styles.icon} spin={isFetching} />
          </div>
        </div>
        <div className={styles.buttonContainer} onClick={onHandleDepositOpen}>
          {t("normal.deposit")}
        </div>
      </div>
    </div>
  );
};

export default BalanceViewer;
