import { Image } from "antd";
import React from "react";
import noData from "./images/noData.png";
import styles from "./style.module.scss";

const NoData = ({ title = "", height = "auto", padding = undefined }) => {
  return (
    <div
      className={styles.container}
      style={{ height: height, padding: padding }}
    >
      <Image width={200} height={200} src={noData} preview={false} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default NoData;
