import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { PRODUCTION_NAME_DEV } from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "antd/dist/antd.variable.min.css";

const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

ConfigProvider.config({
  theme: finalTheme,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //ms time until subsequent request will be triggered again
      staleTime: 3000,
      //when request failed, by default will retry 3x with exponential backoff delay before returning error
      //turn off this option on dev mode to make quicker dev experience
      retry: false,
      //option to refetch data when windows on focus to keep showing latest data to user
      //also turn this off on dev env for simplicity
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <App />
      </ConfigProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
