import axios from "axios";
// import router from '../router/index'
import { DEFAULT_CURRENCY, DEFAULT_LANG } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import { tip, to403Page, toInternetErrorPage, toLogin, toMaintenancePage } from "./utils";
// import router from '../router';
// import store from '../store';
// import React, { useContext } from 'react';
// import { ThemeContext } from './../contexts/ThemeContext';

// const SwitchThemeButton = () => {
//   const context = useContext(ThemeContext);
//   const { theme, toggleTheme } = context;
//   return (
//     <button
//       style={{
//         color: theme.foreground,
//         backgroundColor: theme.background,
//       }}
//       onClick={toggleTheme}
//     >
//       Change Theme
//     </button>
//   );
// };

// export default SwitchThemeButton;

const FORBIDDEN_URL = "https://sheratondongguan.com/";

const logout = () => {
  window.localStorage.removeItem(localStorageKey.TOKEN);
  window.localStorage.removeItem(localStorageKey.USER_INFO);
  setTimeout(() => {
    window.location.href = "/signin";
  }, 1000);
};

const errorHandle = (status, msg, code = undefined) => {
  switch (status) {
    //400: 登入失敗，可能是帳號或是密碼錯誤
    case 400:
      if (code === 7770010) {
        logout();
      }
      tip(msg, code);
      break;
    //401: backend session過期
    case 401:
      //
      if (
        window.location.pathname === "/signin" ||
        window.location.pathname === "/" ||
        window.location.pathname === "/signup"
      ) {
        // if (nuxt.$router.currentRoute.name === "/login") {
        //不用tip, 會由Login去導向首頁
        window.localStorage.removeItem(localStorageKey.TOKEN);
        window.localStorage.removeItem(localStorageKey.USER_INFO);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        //清除token
        window.localStorage.removeItem(localStorageKey.TOKEN);
        window.localStorage.removeItem(localStorageKey.USER_INFO);

        tip(msg, code);
        setTimeout(() => {
          toLogin();
        }, 1000);
      }
      break;

    //403: 權限不足
    case 403:
      if (window.location.pathname === "/accessDenied") {
      } else {
        to403Page();
      }
      break;

    //404: 請求失敗
    case 404:
      tip(msg, code);
      break;

    //503: 維護
    case 503:
      if (
        window.location.pathname === "/inMaintenance" ||
        window.location.pathname === "/signin" ||
        window.location.pathname === "/signup"
      ) {
      } else {
        toMaintenancePage();
      }
      break;

    //510: 站台額度不足
    case 510:
      if (window.location.pathname === "/inMaintenance") {
      } else {
        toMaintenancePage();
      }
      break;

    //其他錯誤，直接拋出提示錯誤
    default:
      console.log("resp沒有攔截到的錯誤:" + msg);
  }
};

//axios的實例
var instance = axios.create({
  baseURL: "/api/",
});

//request攔截器
instance.interceptors.request.use(
  (config) => {
    //發送request前 判斷vuex中是否存在token
    //若存在則統一在http請求的header都加上token
    if (
      (config.params && config.params.needAuth) ||
      (config.data !== undefined && config.data.needAuth)
    ) {
      let token = "";

      if (!!window.localStorage.getItem("token")) {
        token = window.localStorage.getItem("token");
        token = token.replace(/\"/gm, "");
      }
      token && (config.headers.Authorization = "Bearer " + token);
      return config;
    } else {
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

//response攔截器
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      let str = "",
        obj = undefined;
      //L33 transfer user reset password required
      if (response?.data?.result.code && response?.data?.result?.code === 6660001) {
        return Promise.reject(response.data);
      }
      //FIXME:臨時加/game/balance不要有錯誤訊息，之後調整
      if (response.config.url.includes("/game/balance")) {
        str =
          response.data.result === undefined ? response.data.message : response.data.result.message;
        return Promise.reject(str);
      }
      if (response.data.result === undefined) {
        errorHandle(response.status, response.data.message, response.data?.result?.code);
        // obj = undefined;
      } else {
        errorHandle(response.status, response.data.result.message, response.data?.result?.code);
        obj =
          response.data.result.validationErrors === undefined
            ? undefined
            : response.data.result.validationErrors;
      }
      if (obj !== undefined) {
        Object.keys(obj).forEach((key) => {
          str = str + obj[key][0].message + "\n";
        });
        return Promise.reject(str);
      } else {
        str =
          response.data.result === undefined ? response.data.message : response.data.result.message;
        return Promise.reject(str);
      }
    } else {
      if (!window.navigator.onLine) {
        // tip("Network issue, please reconnect and refresh the webpage.");
        toInternetErrorPage();
      } else {
        // window.location.replace(FORBIDDEN_URL);
        return Promise.reject(error);
      }
    }
  },
);
const langSetting = window.localStorage.getItem(localStorageKey.LANG_SETTING);
const langParam = langSetting ? langSetting : DEFAULT_LANG;
const currencySetting = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
const currencyParam = currencySetting ? currencySetting : DEFAULT_CURRENCY;
export default function req(
  method,
  url,
  data = null,
  needAuth = false,
  needCurrency = true,
  needLang = true,
  removeAuth = false,
) {
  method = method.toLowerCase();
  const processData =
    data instanceof FormData
      ? data
      : {
          lang: langParam,
          currency: currencyParam,
          ...data,
        };
  if (!needCurrency) delete processData.currency;
  if (!needLang) delete processData.lang;
  if (!removeAuth) {
    processData.needAuth = needAuth;
  }
  if (method === "post") {
    return instance.post(url, processData);
  } else if (method === "get") {
    return instance.get(url, { params: processData });
  } else if (method === "delete") {
    return instance.delete(url, { params: processData });
  } else if (method === "put") {
    return instance.put(url, processData);
  } else {
    console.error("未知的method" + method);
    return false;
  }
}
