import { Image, Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserGameWallet } from "../../hooks/wallet/wallet.hooks";
import { moneyFormat } from "../../plugins/numberFormat";
import styles from "./style.module.scss";
import { DEFAULT_LANG } from "../../config";
import localStorageKey from "../../enumerations/localStorageKey";

const LANG = localStorage.getItem(localStorageKey.LANG_SETTING) || DEFAULT_LANG;

const WalletItem = ({
  row = {},
  setGameWallets = () => {},
  gameWallets = [],
  imageUrl = "",
  titleStyle = {},
  contentStyle = {},
  maintenanceStyle = {},
}) => {
  const { t } = useTranslation();
  const { code, data, maintenance_status } = row;
  const {
    data: gameWalletData,
    error: gameWalletError,
    isLoading: gameWalletLoading,
  } = useGetUserGameWallet(
    {
      retry: false,
      enabled: maintenance_status === 0,
    },
    code
  );
  useEffect(() => {
    const _wallets = gameWallets;
    const index = _wallets.findIndex((wallet) => wallet.code === code);
    if (index === -1) {
      _wallets.push({
        code: code,
        balance: gameWalletData?.balance || 0,
        value: code,
        label: `${t(`wallet.${code}.title`)}${
          LANG === "zh" ? "" : " "
        }${t(`wallet.${data.text[0].toLowerCase()}.title`)}`,
        maintenance_status: maintenance_status,
      });
    } else {
      _wallets[index] = {
        code: code,
        balance: gameWalletData?.balance || 0,
        value: code,
        label: `${t(`wallet.${code}.title`)}${
          LANG === "zh" ? "" : " "
        }${t(`wallet.${data.text[0].toLowerCase()}.title`)}`,
        maintenance_status: maintenance_status,
      };
    }
    setGameWallets((prevState) => {
      return [...new Set([...prevState, ..._wallets])];
    });
  }, [gameWalletData, maintenance_status]);
  useEffect(() => {
    console.log("wallet rr ", code);
  }, [data]);

  return (
    <div className={`${styles.walletColContainer}`}>
      <div className={styles.walletCol}>
        <Image style={{ width: "60px" }} preview={false} src={imageUrl} />
        <div className={styles.walletTitle} style={titleStyle}>{`${t(
          `wallet.${code}.title`
        )}${LANG === "zh" ? "" : " "}${t(
          `wallet.${data.text[0].toLowerCase()}.title`
        )}`}</div>
      </div>
      {maintenance_status === 1 ? (
        <div className={styles.maintenance} style={maintenanceStyle}>
          {t("normal.maintenance")}
        </div>
      ) : gameWalletLoading ? (
        <Spin size={"small"} />
      ) : (
        <div className={styles.walletContent} style={contentStyle}>
          {gameWalletData?.balance
            ? moneyFormat(gameWalletData?.balance)
            : "0.00"}
        </div>
      )}
    </div>
  );
};

export default WalletItem;
