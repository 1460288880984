import { Button, Divider, Drawer, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../CustomInput/index.jsx";
import { AppBar, CheckBoxSelector } from "../index.js";
// import langDict from "./langDict.json";
import {
  useGetUserAreaCode,
  useGetUserForgetSendVerify,
  usePostMobileSend,
  usePostMobileVerify,
  usePutForgetPassword,
} from "./hooks/bind.hook";

const COOLDOWN_SEC = 60;
let cooldownNumber;
let cooldownInterval;
const BindMobilePopup = ({
  visible,
  setVisible,
  onBeforeFinish,
  isForgetPassword = false,
  account,
  title = null,
}) => {
  const [cooldown, setCooldown] = useState();
  const [showSelectAreaCode, setShowAreaCode] = useState(false);
  const [areaCodeSelected, setAreaCodeSelected] = useState();
  const [sendCount, setSendCount] = useState(0);
  const { data: areaCode } = useGetUserAreaCode({
    enabled: visible === true,
  });
  const { mutate: mobileSend } = usePostMobileSend();
  const { mutate: forgetMobileSend } = useGetUserForgetSendVerify();
  const { mutate: mobileVerify } = usePostMobileVerify({
    onSuccess: onBeforeFinish,
  });

  const { mutate: forgetPassword } = usePutForgetPassword({
    onSuccess: onBeforeFinish,
  });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const currentAreaCode = areaCode?.find((a) => a.area_code === areaCodeSelected);
  const initCoolDown = () => {
    setCooldown(COOLDOWN_SEC);
    cooldownNumber = COOLDOWN_SEC;
    cooldownInterval = setInterval(() => {
      setCooldown(cooldownNumber - 1);
      cooldownNumber = cooldownNumber - 1;
      if (cooldownNumber <= 0) {
        setCooldown(undefined);
        cooldownNumber = undefined;
        clearInterval(cooldownInterval);
      }
    }, 1000);
  };

  const onClose = () => {
    setVisible(false);
  };
  const getCode = async () => {
    try {
      const mobileProviderArr = currentAreaCode?.mobile_provider?.split(",");
      await form.validateFields(["mobile"]);
      const params = {
        mobile: form.getFieldValue("mobile"),
        area_code: areaCodeSelected,
        type:
          mobileProviderArr && mobileProviderArr.length > 0
            ? mobileProviderArr[sendCount % mobileProviderArr?.length || 0]
            : "",
      };
      if (isForgetPassword) {
        forgetMobileSend({
          ...params,
          area_code: areaCodeSelected,
          type: "mobile",
          account,
        });
      } else {
        mobileSend(params);
      }

      initCoolDown();
      setSendCount(sendCount + 1);
    } catch (err) {
      console.error(err);
    }
  };
  const onFinish = (form) => {
    if (isForgetPassword) {
      forgetPassword({ ...form, account: account, type: "mobile" });
    } else {
      mobileVerify({ ...form, area_code: areaCodeSelected });
    }
  };

  const renderTitle = () => {
    if (isForgetPassword === true) return null;
    return (
      <div
        style={{
          color: "var(--color-text-main)",
          fontSize: "var(--fontSetting-huge)",
        }}
      >
        {t("normal.bindMobile")}
      </div>
    );
  };

  const renderMobile = () => {
    if (isForgetPassword === true) return null;
    return (
      <CustomInput
        name="mobile"
        label={t("normal.mobile")}
        addonBefore={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowAreaCode(true);
            }}
          >
            <div>{currentAreaCode ? `+${currentAreaCode?.area_code}` : ""}</div>
            <Divider type="vertical" />
          </div>
        }
        placeholder={t("form.holder.pleaseEnter")}
        rules={[
          {
            required: true,
            message: t("form.required", { name: t("normal.mobile") }),
          },
        ]}
      />
    );
  };

  const renderForgetPasswordForm = () => {
    if (isForgetPassword === false) return null;
    return (
      <>
        <CustomInput
          name="new_password"
          label={t("signup.newPassword")}
          placeholder={t("form.holder.pleaseEnter")}
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.newPassword"),
              }),
            },
            {
              min: 6,
              max: 16,
              message: t("normal.validation.length.error", {
                name: t("signup.newPassword"),
                min: 6,
                max: 16,
              }),
            },
          ]}
        />
        <CustomInput
          name="confirm_new_password"
          label={t("signup.confirmNewPassword")}
          dependencies={["password"]}
          placeholder={t("form.holder.pleaseEnter")}
          password={true}
          rules={[
            {
              required: true,
              message: t("form.required", {
                name: t("signup.confirmNewPassword"),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t("signup.confirmPasswordError")));
              },
            }),
          ]}
        />
      </>
    );
  };

  useEffect(() => {
    if (areaCode?.length > 0) {
      setAreaCodeSelected(areaCode[0]?.area_code);
    }
  }, [areaCode]);

  useEffect(() => {
    console.log(areaCodeSelected);
  }, [areaCodeSelected]);

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        destroyOnClose={true}
        bodyStyle={{ padding: "0" }}
        width={"100%"}
        style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
      >
        <AppBar
          title={title ? title : t("normal.bindMobile")}
          onClickEvent={() => {
            setVisible(false);
          }}
        />
        <div style={{ padding: 16 }}>
          {renderTitle()}
          <Form
            form={form}
            name="resetPassword"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
          >
            {renderMobile()}
            <CustomInput
              name="code"
              label={t("forgot.verifyMobileCode")}
              placeholder={t("form.holder.pleaseEnter")}
              addonAfter={
                <div
                  style={{
                    color:
                      cooldown === undefined
                        ? "var(--color-login-button-text-active)"
                        : "var(--color-text-unstable)",
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (cooldown === undefined) {
                      getCode();
                    }
                  }}
                >
                  {cooldown === undefined
                    ? t("forgot.sendCode")
                    : t("forgotPassword.cooldown", { sec: cooldown })}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: t("form.required", {
                    name: t("forgot.verifyCode"),
                  }),
                },
              ]}
            />

            {renderForgetPasswordForm()}

            <Button
              block
              size="large"
              style={{ width: "100%", borderRadius: "8px" }}
              type="primary"
              htmlType="submit"
            >
              {t("normal.confirm")}
            </Button>
          </Form>
        </div>
      </Drawer>
      <Drawer
        visible={showSelectAreaCode}
        onClose={() => {
          setShowAreaCode(false);
        }}
        width={"100%"}
        destroyOnClose={true}
        closable={false}
        bodyStyle={{ padding: "0" }}
        style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
      >
        <AppBar
          title={t("normal.areaCode")}
          onClickEvent={() => {
            setShowAreaCode(false);
          }}
        />
        <CheckBoxSelector
          options={areaCode?.map((a) => {
            return {
              label: a.area_code,
              value: a.area_code,
            };
          })}
          selectedOption={areaCodeSelected}
          onChange={(e) => {
            setAreaCodeSelected(e.target.value);
          }}
        />
      </Drawer>
    </>
  );
};

export default BindMobilePopup;
