import { CopyFilled } from "@ant-design/icons";
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { copyText } from "../../plugins/utils";
import VIPIcon from "../VIPIcon";
import { BalanceViewer, PersonalMenu } from "./components";
import styles from "./style.module.scss";
const AccountInfoPersonal = ({ userInfo, isLoading = true }) => {
  const { t } = useTranslation();
  const [userLevel, setUserLevel] = useState("");
  const [userIconId, setUserIconId] = useState();
  const navigate = useNavigate();

  const vipNameProccessor = (levelName) => {
    const num = levelName.replace("VIP", "");
    const final = "V" + num;
    setUserLevel(final);
  };
  const findAvatar = (userId) => {
    const iconId = parseInt(userId) % 20;
    // console.log("iconId", iconId);
    setUserIconId(iconId + 1);
  };

  const handleCopy = (uid) => {
    copyText(uid, t("normal.copy.complete"));
  };

  useEffect(() => {
    if (userInfo?.level_name) {
      vipNameProccessor(userInfo.level_name);
    }
    if (userInfo?.account_id) {
      findAvatar(userInfo.account_id);
    }
  }, [userInfo]);

  return (
    <div className={styles.container}>
      <div className={`${styles.accountInfo}`}>
        {isLoading ? (
          <>
            <Skeleton.Avatar active />
            &nbsp;
            <Skeleton.Input active size={"small"} block />
          </>
        ) : (
          <>
            <div className={`${styles.leftBox}`}>
              <img
                src={`/images/avatar/avatar${userIconId}.png`}
                alt="avatar"
                className={`${styles.userIcon}`}
              />
              <div className={`${styles.emailBox}`}>
                <div className={`${styles.account}`}>{userInfo?.account}</div>
                <div className={styles.uidContainer}>
                  <div className={`${styles.uid}`}>{`ID: ${userInfo?.account_id}`}</div>
                  <div className={styles.copyIcon} onClick={() => handleCopy(userInfo?.account_id)}>
                    <CopyFilled />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.vipLabelBox}`}
              onClick={() => {
                // if (window.location.pathname === "/bonus") return;
                // navigate("/bonus?tab=vip");
                navigate("/vip");
              }}
            >
              {/* <div className={`${styles.vipLabel}`}> */}
              {/* <img src={``} alt="" className={`${styles.vipIcon}`} /> */}
              <VIPIcon vipLevel={userInfo?.level_name} imageStyle={styles.vipImageStyle} />
              {/* {userLevel} */}
              {/* </div> */}
            </div>
          </>
        )}
      </div>
      <BalanceViewer />
      <PersonalMenu />
      <div className={styles.wrapper}>
        <div className={styles.background} />
      </div>
    </div>
  );
};

export default AccountInfoPersonal;
