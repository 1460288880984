import { lazy } from "react";

const AccessDenied = lazy(() => import("../pages/AccessDenied"));
const ActivityDescription = lazy(() => import("../pages/ActivityDescription"));
const Announce = lazy(() => import("../pages/Announce"));
const AppDownload = lazy(() => import("../pages/AppDownload"));
const BetRecord = lazy(() => import("../pages/BetRecord"));
const BitGame = lazy(() => import("../pages/BitGame"));
const CommonIssues = lazy(() => import("../pages/CommonIssues"));
const EmailVerify = lazy(() => import("../pages/EmailVerify"));
const Event = lazy(() => import("../pages/Event"));
// const FiatChannel = lazy(() => import("../pages/Exchange/ChannelSelect"));
// const ExchangeForm = lazy(() => import("../pages/Exchange/Form"));
const Exchange = lazy(() => import("../pages/Exchange"));
const ExchangeRecord = lazy(() => import("../pages/Exchange/Record"));
const ExchangeHistory = lazy(() => import("../pages/ExchangeHistory"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const GophersGame = lazy(() => import("../pages/GophersGame"));
const Home = lazy(() => import("../pages/Home"));
const InMaintenance = lazy(() => import("../pages/InMaintenance"));
const LaunchGame = lazy(() => import("../pages/LaunchGame"));
const LoteriaFederal = lazy(() => import("../pages/LoteriaFederal"));
const Notice = lazy(() => import("../pages/Notice"));
const Personal = lazy(() => import("../pages/Personal"));
const PersonalInfo = lazy(() => import("../pages/PersonalInfo"));
const SignIn = lazy(() => import("../pages/SignIn"));
const SignUp = lazy(() => import("../pages/SignUp"));
const Transfer = lazy(() => import("../pages/Transfer"));
const Wallet = lazy(() => import("../pages/Wallet"));
const WinGame = lazy(() => import("../pages/WinGame"));
const WithdrawManagerForm = lazy(() => import("../pages/WithdrawManager/Form"));
const WithdrawManager = lazy(() => import("../pages/WithdrawManager/Landing"));
const Vip = lazy(() => import("../pages/Vip"));
const BonusWheel = lazy(() => import("../pages/BonusWheel"));
const ConnectionFailed = lazy(() => import("../pages/ConnectionFailed"));
// nav left: backURL,
// nav center: title, titleEllipsis,
const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
    navType: {
      hideHeader: true,
      left: {
        type: "user",
      },
      center: {},
      right: { type: "user" },
      background: { start: "var(--color-home-bg-start)", end: "var(--color-home-bg-end)" },
      footerBoxBg: "var(--color-home-bg-end)",
    },
  },
  {
    path: "/lotwin",
    name: "lotwin",
    component: WinGame,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/lotbit",
    name: "lotbit",

    component: BitGame,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/lotlaunch",
    name: "lotlaunch",
    component: LaunchGame,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--color-launchGame-background)",
    },
  },
  {
    path: "/lotgophers",
    name: "lotgophers",
    component: GophersGame,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--color-launchGame-background)",
    },
  },
  {
    path: "/lotfederal",
    name: "lotfederal",
    component: LoteriaFederal,
    navType: {
      hideHeader: true,
      hideFooter: true,
      background: "var(--color-launchGame-background)",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    navType: {
      // doNotInitWindowHeight: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
    navType: {
      // doNotInitWindowHeight: true,
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
    navType: {
      hideHeader: false,
      left: {
        backURL: "/signin",
      },
      center: {
        title: "forgot.forgotPassword",
      },
      hideFooter: true,
    },
  },
  {
    path: "/announce",
    name: "announce",
    component: Announce,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: -1,
      },
      center: {
        title: "normal.announce",
      },
      background: "var(--color-bg-main)",
    },
  },
  {
    path: "/notice",
    name: "notice",
    component: Notice,
    navType: {
      hideHeader: true,
      hideFooter: true,
      left: {
        backURL: "/personal",
      },
      center: {
        title: "normal.notice",
      },
      background: "var(--color-bg-main)",
    },
  },
  {
    path: "/transfer",
    name: "transfer",
    component: Transfer,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: "/wallet",
      },
      center: {
        title: "transfer.title",
      },
    },
  },
  {
    path: "/exchange",
    name: "exchange",
    component: Exchange,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/exchange/record",
    name: "exchangeRecord",
    component: ExchangeRecord,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: -1,
      },
      center: {
        title: "exchange.record",
      },
    },
  },
  // {
  //   path: "/exchange/channel",
  //   name: "exchange-channel",
  //   component: FiatChannel,
  //   navType: {
  //     hideHeader: true,
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: "/exchange/form",
  //   name: "exchange-form",
  //   component: ExchangeForm,
  //   navType: {
  //     hideHeader: true,
  //     hideFooter: true,
  //   },
  // },
  {
    path: "/exchange/history",
    name: "exchange-history",
    component: ExchangeHistory,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: -1,
      },
      center: {
        title: "transaction.record.title",
      },
    },
  },
  {
    path: "/wallet/manager",
    name: "wallet-manager",
    component: WithdrawManager,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: "/personal",
      },
      center: {
        title: "withdraw.manage.title",
      },
    },
  },
  {
    path: "/home/wallet/manager",
    name: "wallet-manager",
    component: WithdrawManager,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: -1,
      },
      center: {
        title: "withdraw.manage.title",
      },
    },
  },
  {
    path: "/wallet/manager/form",
    name: "wallet-manager-form",
    component: WithdrawManagerForm,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/bonus",
    name: "bonus",
    component: Event,
    navType: {
      hideHeader: false,
      center: {
        title: "footer.bonus",
      },
    },
  },
  {
    path: "/home/bonus",
    name: "homeBonus",
    component: Event,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: -1,
      },
      center: {
        title: "footer.bonus",
      },
    },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
    navType: {
      hideHeader: true,
      footerBoxBg: "var(--color-bg-highlight-2)",
      center: {
        title: "footer.wallet",
      },
    },
  },
  {
    path: "/personal",
    name: "personal",
    component: Personal,
    navType: {
      hideHeader: true,
      left: {
        // backURL: "/",
      },
      center: {
        title: "page.personal",
      },
      background: "var(--color-bg-secondary)",

      footerBoxBg: "var(--color-bg-secondary)",
    },
  },
  {
    path: "/personal/info",
    name: "personalInfo",
    component: PersonalInfo,
    navType: {
      hideHeader: false,
      left: {
        backURL: "/personal",
      },
      center: {
        title: "page.personal.info",
      },
      hideFooter: true,
    },
  },
  {
    path: "/commonIssues",
    name: "commonIssues",
    component: CommonIssues,
    navType: {
      hideHeader: false,
      left: {
        backURL: -1,
      },
      center: {
        title: "page.helpCenter",
      },
      hideFooter: true,
    },
  },
  {
    path: "/betRecord",
    name: "betRecord",
    component: BetRecord,
    navType: {
      hideHeader: true,
      // left: {
      //   backURL: -1,
      // },
      // center: {
      //   title: "page.betRecord",
      // },
      hideFooter: true,
    },
  },
  // {
  //   path: "/lotwin/betRecord",
  //   name: "winBetRecord",
  //   component: BetRecord,
  //   navType: {
  //     hideHeader: true,
  //     // left: {
  //     //   backURL: "/lotwin",
  //     // },
  //     // center: {
  //     //   title: "page.betRecord",
  //     // },
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: "/lotbit/betRecord",
  //   name: "bitBetRecord",
  //   component: BetRecord,
  //   navType: {
  //     hideHeader: true,
  //     // left: {
  //     //   backURL: "/lotbit",
  //     // },
  //     // center: {
  //     //   title: "page.betRecord",
  //     // },
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: "/lotlaunch/betRecord",
  //   name: "launchBetRecord",
  //   component: BetRecord,
  //   navType: {
  //     hideHeader: true,
  //     // left: {
  //     //   backURL: "/lotlaunch",
  //     // },
  //     // center: {
  //     //   title: "page.betRecord",
  //     // },
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: "/lotgophers/betRecord",
  //   name: "launchBetRecord",
  //   component: BetRecord,
  //   navType: {
  //     hideHeader: false,
  //     left: {
  //       backURL: "/lotgophers",
  //     },
  //     center: {
  //       title: "page.betRecord",
  //     },
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: "/lotfederal/betRecord",
  //   name: "launchBetRecord",
  //   component: BetRecord,
  //   navType: {
  //     hideHeader: false,
  //     left: {
  //       backURL: "/lotfederal",
  //     },
  //     center: {
  //       title: "page.betRecord",
  //     },
  //     hideFooter: true,
  //   },
  // },
  {
    path: "/emailVerify",
    name: "emailVerify",
    component: EmailVerify,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/accessDenied",
    name: "accessDenied",
    component: AccessDenied,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/inMaintenance",
    name: "inMaintenance",
    component: InMaintenance,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/appDownload",
    name: "appDownload",
    component: AppDownload,
    navType: {
      hideHeader: true,
      hideFooter: true,
      // left: {
      //   backURL: -1,
      // },
      // center: {
      //   title: "win.appDownload.title",
      // },
    },
  },
  {
    path: "/activityDescription",
    name: "activityDescription",
    component: ActivityDescription,
    navType: {
      hideHeader: false,
      hideFooter: true,
      left: {
        backURL: "/",
      },
      center: {
        title: "activityDescription.title",
      },
    },
  },
  {
    path: "/vip",
    name: "vip",
    component: Vip,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/bonusWheel",
    name: "bonusWheel",
    component: BonusWheel,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: "/connectionFailed",
    name: "connectionFailed",
    component: ConnectionFailed,
    navType: {
      hideHeader: true,
      hideFooter: true,
    },
  },
];
export default RouterList;
