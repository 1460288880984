
const CDN_URL = "https://bluebirds-dev.s3.ap-east-1.amazonaws.com";
const GLOBAL_CDN = "https://bluebirds-dev.s3.ap-east-1.amazonaws.com";
const DECIMAL_SEPARATOR = '.';
const DEFAULT_CURRENCY = 'brl';
const TIMEZONE_CURRENCY = {};
const DEFAULT_LANG = 'pt';
const FIXED_NUMBER = 5;
const MIN_DECIMAL_POINT = 2;
const MAX_DECIMAL_POINT = 2;
const GLOBAL_SETTING_API = "globalsettingdev.meta188.cc/api";
const GLOBAL_USER_API = "globaluserdev.meta188.cc/api";
const HOME_HEADER_BACKGROUND_IMAGE = '/images/logo/cc188/background_header.png';
const IMAGE_API = "devimage.bluebirds.cc";
const MAIN_API = "dev.meta188.cc/api";
const PC_URL = "http://1bbetv2devweb.meta188.cc/";
const WIN_URL = "gamewinlotteryapidev.meta188.cc";
const BINANCE_URL = "https://api.binance.com/api/v3";
const PRODUCTION_NAME_DEV = '1bbetv2';
const PRODUCTION_NAME_DISPLAY = 'LUCKYHA';
const BASE_PROJECT = ["1bbetv2"]
const PROTOCOL = "http";
const THOUSANDS_SEPARATOR = ',';
const AGENT_CENTER_URL = "http://agentdevweb.meta188.cc";
const AGENT_MOBILE_URL = "http://1bbetv2agentdev.meta188.cc/";
const HEADER_SHOW_TRANS_RECORD = true;
const GAME_SOCKET_URL = "gamewinlotterydatadev.meta188.cc/ws";
const STOCK_SOCKET_URL = "game77lotterydatadev.meta188.cc/ws";
const WS_PROTOCOL = "ws";
const ADD_PADDING_BOTTOM = '72px';
const HIDE_VIP_CATEGORY = []
const EXTERNAL_GAME = []
const ACCOUNT_VERIFY_METHOD = ["email","phone"]
const GCODE = "";
const DOWNLOAD_PAGE_LOGO_FILE_EXTENTION = 'png';
const IS_WALLET_IN_PERSONAL_PAGE = true;
const DOUBLE_SEVEN_MOBILE_URL = "http://game77lotterydev.meta188.cc";
const MG_SLOT_MAIN_API = "gamemgapidev.meta188.cc/api";
const T1_MAIN_API = "gamet1apidev.meta188.cc/api";
const EVO_MAIN_API = "gameevoapidev.meta188.cc/api";
const PP_SLOT_MAIN_API = "gameppapidev.meta188.cc/api";
const PG_SLOT_MAIN_API = "gamepgapidev.meta188.cc/api";
const YGG_SLOT_MAIN_API = "gameyggapidev.meta188.cc/api";
const EVOPLAY_SLOT_MAIN_API = "gameevoplayapidev.meta188.cc/api";
const JILI_SLOT_MAIN_API = "gamejiliapidev.meta188.cc/api";
const DOUBLE_SEVEN_MAIN_API = "game77lotteryapidev.meta188.cc/api";
const GALAXSYS_MAIN_API = "gamegalaxsysapidev.meta188.cc/api";
const SPRIBE_MAIN_API = "gamejdbapidev.meta188.cc/api";
const PT_MAIN_API = "gameptapidev.meta188.cc/api";
const PPLIVE_MAIN_API = "gameppliveapidev.meta188.cc/api";
const ONEPLAY_MAIN_API = "gameoneapidev.meta188.cc/api";
const PLAYSTAR_MAIN_API = "gameplaystarapidev.meta188.cc/api";
const WEBLOCKCHAIN_MAIN_API = "gameweapidev.meta188.cc/api";
const WELOTTERY_MAIN_API = "gameweapidev.meta188.cc/api";
const WELIVE_MAIN_API = "gameweapidev.meta188.cc/api";
const TCG_MAIN_API = "gametcgapidev.meta188.cc/api";
const YOUBET_MAIN_API = "gameyoubetapidev.meta188.cc/api";
const AESEXY_MAIN_API = "gameaesexyapidev.meta188.cc/api";
const HIDE_OFFLINE_BANK_CARD_DEPOSIT = false;
const TELEGRAM_URL = "";
const LAST_PLAY_COUNT = 0;
const GOOGLE_PLAY_DOWNLOAD_URL = 'https://play.google.com/store/apps/details?id=com.zvefwfwe.dzwewwe';
const APPLICATION_VERSION = "3.1.4";
const SHOW_WITHDRAW_LIST = false;
const SHOW_PROMOTION_DATE = true;
const MIN_USDT_WITHDRAW_LIMIT = 0;
const MAX_USDT_WITHDRAW_LIMIT = 0;
const DOUBLE7_LOTTERY_SHOW_POPUP = true;
const HIDE_PROFILE_PHONE = false;
const ENV = "dev";

 export {
CDN_URL,
GLOBAL_CDN,
DECIMAL_SEPARATOR,
DEFAULT_CURRENCY,
TIMEZONE_CURRENCY,
DEFAULT_LANG,
FIXED_NUMBER,
MIN_DECIMAL_POINT,
MAX_DECIMAL_POINT,
GLOBAL_SETTING_API,
GLOBAL_USER_API,
HOME_HEADER_BACKGROUND_IMAGE,
IMAGE_API,
MAIN_API,
PC_URL,
WIN_URL,
BINANCE_URL,
PRODUCTION_NAME_DEV,
PRODUCTION_NAME_DISPLAY,
BASE_PROJECT,
PROTOCOL,
THOUSANDS_SEPARATOR,
AGENT_CENTER_URL,
AGENT_MOBILE_URL,
HEADER_SHOW_TRANS_RECORD,
GAME_SOCKET_URL,
STOCK_SOCKET_URL,
WS_PROTOCOL,
ADD_PADDING_BOTTOM,
HIDE_VIP_CATEGORY,
EXTERNAL_GAME,
ACCOUNT_VERIFY_METHOD,
GCODE,
DOWNLOAD_PAGE_LOGO_FILE_EXTENTION,
IS_WALLET_IN_PERSONAL_PAGE,
DOUBLE_SEVEN_MOBILE_URL,
MG_SLOT_MAIN_API,
T1_MAIN_API,
EVO_MAIN_API,
PP_SLOT_MAIN_API,
PG_SLOT_MAIN_API,
YGG_SLOT_MAIN_API,
EVOPLAY_SLOT_MAIN_API,
JILI_SLOT_MAIN_API,
DOUBLE_SEVEN_MAIN_API,
GALAXSYS_MAIN_API,
SPRIBE_MAIN_API,
PT_MAIN_API,
PPLIVE_MAIN_API,
ONEPLAY_MAIN_API,
PLAYSTAR_MAIN_API,
WEBLOCKCHAIN_MAIN_API,
WELOTTERY_MAIN_API,
WELIVE_MAIN_API,
TCG_MAIN_API,
YOUBET_MAIN_API,
AESEXY_MAIN_API,
HIDE_OFFLINE_BANK_CARD_DEPOSIT,
TELEGRAM_URL,
LAST_PLAY_COUNT,
GOOGLE_PLAY_DOWNLOAD_URL,
APPLICATION_VERSION,
SHOW_WITHDRAW_LIST,
SHOW_PROMOTION_DATE,
MIN_USDT_WITHDRAW_LIMIT,
MAX_USDT_WITHDRAW_LIMIT,
DOUBLE7_LOTTERY_SHOW_POPUP,
HIDE_PROFILE_PHONE,
ENV,
}