import { requestGlobalSettingData } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const currency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetAllLangList = (params) =>
  requestGlobalSettingData("get", `/language`, params, true, false);

export const apiGetLangListByCurrency = (params) =>
  requestGlobalSettingData(
    "get",
    `/${currency}/language/menu`,
    params,
    true,
    false
  );
