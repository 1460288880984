import { requestGlobalSettingData, requestMain } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const CURRENCY = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiGetUserWallet = (params) =>
  requestMain("get", `/${CURRENCY}/user/wallet`, params, true, false);

export const apiGetUserGameWallet = (params) =>
  requestMain("get", `/${CURRENCY}/game/balance`, params, true, false);

export const apiGetGameList = (params) => 
  requestGlobalSettingData("get", `/${CURRENCY}/game/list`, params, true, false);



export const apiTransferMoneyToGame = (params) =>
  requestMain("post", `/${CURRENCY}/game/transfer/to`, params, true, false);

export const apiTransferMoneyToMainWallet = (params) =>
  requestMain("post", `/${CURRENCY}/game/transfer/back`, params, true, false);