import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomModal, CustomerServiceDrawer, toast } from "../components";
import BindEmailPopup from "../components/BindEmailPopup";
import BindMobilePopup from "../components/BindMobilePopup";
import ConfirmValidatePopup from "../components/ConfirmValidatePopup";
import CustomerServiceListDrawer from "../components/CustomerServiceListDrawer";
import { DEFAULT_CURRENCY, TIMEZONE_CURRENCY } from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import {
  useGetAllCustomerService,
  useGetCustomerService,
} from "../hooks/customerService/customerService.hook";
import { useGetUserInfo, useLogout } from "../hooks/personal/personal.hook";
import { useDefaultLayout } from "../layouts/defaultLayout";

const LoginContext = createContext({
  isLogin: false,
  setIsLogin: () => {},
  checkIfLogin: () => {},
  userInfo: {},
  setIsHaveInvitation: () => {},
  isHaveInvitation: false,
  agentCode: undefined,
  setAgentCode: () => {},
  setShowCustomerDrawer: () => {},
  csLink: undefined,
  activeCSLink: undefined,
  setActiveCSLink: () => {},
  openCSDrawer: () => {},
});

export const LoginContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);
  const [showConfirmValidateModal, setShowConfirmValidateModal] = useState(false);
  const [showConfirmValidatePopup, setShowConfirmValidatePopup] = useState(false);
  const [showBindMobile, setShowBindMobile] = useState(false);
  const [showBindEmail, setShowBindEmail] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [isHaveInvitation, setIsHaveInvitation] = useState(false);
  const [agentCode, setAgentCode] = useState(undefined);

  const { mutate: logout } = useLogout();
  const { setCheckLoginProcess } = useDefaultLayout();
  const { data: userInfo } = useGetUserInfo({
    enabled: !!isLogin,
  });

  const { data: csLink } = useGetCustomerService({ enabled: !!isLogin });
  const { data: allcsLink } = useGetAllCustomerService({ enabled: !!isLogin === false });

  const [activeCSLink, setActiveCSLink] = useState(undefined);
  const [showCustomerListDrawer, setShowCustomerListDrawer] = useState(false);
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);

  const checkIfLogin = () => {
    if (!isLogin) {
      setShowNeedLoginModal(true);
      return false;
    } else {
      if (userInfo?.mobile_status === 0 && userInfo?.email_status === 0) {
        setShowConfirmValidateModal(true);
        return false;
      }
      return true;
    }
  };

  const openCSDrawer = () => {
    if (isLogin) {
      setActiveCSLink(csLink);
      setShowCustomerDrawer(true);
    } else {
      setShowCustomerListDrawer(true);
    }
  };

  const getTimeZoneAndCurrency = () => {
    const currencySetting = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
    if (currencySetting == "rmb") {
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, DEFAULT_CURRENCY);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (isLogin == false && !currencySetting) {
      //isLogin有可能是undefined
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currency = TIMEZONE_CURRENCY[timeZone] ?? DEFAULT_CURRENCY;
      window.localStorage.setItem(localStorageKey.DEFAULT_CURRENCY, currency);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleCSDrawerVisible = () => {
    setShowCustomerDrawer(false);
    setActiveCSLink(undefined);
  };

  useEffect(() => {
    if (csLink) {
      setActiveCSLink(csLink);
    }
  }, [csLink]);

  useEffect(() => {
    const userIsLogin = Boolean(
      window.localStorage.getItem(localStorageKey.USER_INFO) &&
        window.localStorage.getItem(localStorageKey.TOKEN),
    );
    setIsLogin(userIsLogin);
  }, []);

  useEffect(() => {
    setCheckLoginProcess(checkIfLogin);
  }, [userInfo, isLogin]);

  useEffect(() => {
    getTimeZoneAndCurrency();
  }, [isLogin]);

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setIsLogin,
        checkIfLogin,
        userInfo,
        setIsHaveInvitation,
        isHaveInvitation,
        agentCode,
        setAgentCode,
        setShowCustomerDrawer,
        csLink,
        activeCSLink,
        setActiveCSLink,
        openCSDrawer,
      }}
    >
      {children} {/* NEED LOGIN MODAL */}
      <CustomModal
        isModalVisible={showNeedLoginModal}
        setIsModalVisible={setShowNeedLoginModal}
        title={t("normal.notLoggedIn")}
        content={t("normal.notLoggedIn.hint")}
        needIcon={true}
        okBtnText={t("normal.forwardTo")}
        cancelBtnText={t("normal.cancel")}
        onOk={() => {
          setShowNeedLoginModal(false);
          if (isHaveInvitation === true) {
            navigate(`/signup?invite=${agentCode}`);
          } else {
            navigate("/signin");
          }
        }}
      />
      {/* VALIDATION VERIFICATION */}
      <CustomModal
        isModalVisible={showConfirmValidateModal}
        setIsModalVisible={setShowConfirmValidateModal}
        title={t("verifymodal.title")}
        content={t("verifymodal.content")}
        needIcon={true}
        okBtnText={t("verifymodal.goValidate")}
        cancelBtnText={t("personal.signOut")}
        showCloseButton={true}
        onOk={() => {
          setShowConfirmValidateModal(false);
          setShowConfirmValidatePopup(true);
        }}
        onCancel={logout}
      />
      <ConfirmValidatePopup
        visible={showConfirmValidatePopup}
        setVisible={setShowConfirmValidatePopup}
        onMobileClick={() => {
          setShowBindMobile(true);
        }}
        onEmailClick={() => {
          setShowBindEmail(true);
        }}
      />
      {/* BIND EMAIL AND PHONE POPUPS */}
      <BindEmailPopup
        visible={showBindEmail}
        setVisible={setShowBindEmail}
        onBeforeFinish={() => {
          setShowBindEmail(false);
          setShowConfirmValidatePopup(false);
          setTimeout(() => {
            toast({ content: t("normal.success"), type: "success" });
            window.location.reload();
          }, 1000);
        }}
      />
      <BindMobilePopup
        visible={showBindMobile}
        setVisible={setShowBindMobile}
        onBeforeFinish={() => {
          setShowBindMobile(false);
          setShowConfirmValidatePopup(false);
          setTimeout(() => {
            toast({ content: t("normal.success"), type: "success" });
            window.location.reload();
          }, 1000);
        }}
      />
      <CustomerServiceDrawer
        csLink={activeCSLink}
        visible={showCustomerDrawer}
        handleVisible={handleCSDrawerVisible}
      />
      <CustomerServiceListDrawer
        csList={allcsLink}
        visible={showCustomerListDrawer}
        handleVisible={setShowCustomerListDrawer}
      />
    </LoginContext.Provider>
  );
};
export const useLogin = () => {
  const LoginValue = useContext(LoginContext);
  return LoginValue;
};
