import { Button } from "antd";
import "./style.scss";

const CustomButton = ({
  block = false,
  danger = false,
  disabled = false,
  ghost = false,
  htmlType = "button",
  icon = "",
  loading = false,
  shape = "default",
  size = "middle",
  type = "default",
  onClick = () => {},
  className = "",
  children,
  style = {},
}) => {
  return (
      <Button 
        className={`custom_button ${className}`}
        style={style}
        block={block}
        danger={danger}
        disabled={disabled}
        ghost={ghost}
        htmlType={htmlType}
        icon={icon}
        loading={loading}
        shape={shape}
        size={size}
        type={type}
        onClick={onClick}
      >
        {children}
      </Button>
  )
}

export default CustomButton;