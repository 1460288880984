import {
  AESEXY_MAIN_API,
  BINANCE_URL,
  DOUBLE_SEVEN_MAIN_API,
  EVOPLAY_SLOT_MAIN_API,
  EVO_MAIN_API,
  GLOBAL_SETTING_API,
  GLOBAL_USER_API,
  IMAGE_API,
  JILI_SLOT_MAIN_API,
  MAIN_API,
  MG_SLOT_MAIN_API,
  ONEPLAY_MAIN_API,
  PG_SLOT_MAIN_API,
  PLAYSTAR_MAIN_API,
  PPLIVE_MAIN_API,
  PP_SLOT_MAIN_API,
  PROTOCOL,
  PT_MAIN_API,
  SPRIBE_MAIN_API,
  T1_MAIN_API,
  TCG_MAIN_API,
  WEBLOCKCHAIN_MAIN_API,
  WELIVE_MAIN_API,
  WELOTTERY_MAIN_API,
  WIN_URL,
  YGG_SLOT_MAIN_API,
  YOUBET_MAIN_API,
} from "../config";
import localStorageKey from "../enumerations/localStorageKey";
import req from "./https.js";
import { isAllowedWithoutCurrency, toLogin } from "./utils";

let currentCurrency = null;

if (window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY) === null) {
  if (isAllowedWithoutCurrency(window.location.pathname) === false) {
    toLogin();
  }
} else {
  currentCurrency = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
}

export const request = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${currentCurrency}${MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestGlobalSettingData = (
  method,
  path,
  data = null,
  needAuth = false,
  needCurrency = true,
) => req(method, `${PROTOCOL}://${GLOBAL_SETTING_API}${path}`, data, needAuth, needCurrency);

export const requestGlobalUserData = (
  method,
  path,
  data = null,
  needAuth = false,
  needCurrency = true,
) => req(method, `${PROTOCOL}://${GLOBAL_USER_API}${path}`, data, needAuth, needCurrency);

export const requestMain = (method, path, data = null, needAuth = false, needCurrency = true) => {
  const CURRENCY = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);
  return req(method, `${PROTOCOL}://${CURRENCY}${MAIN_API}${path}`, data, needAuth, needCurrency);
};

export const requestImage = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${IMAGE_API}${path}`, data, needAuth, needCurrency);

export const requestWin = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${WIN_URL}${path}`, data, needAuth, needCurrency);

export const requestBinance = (method, path, data = null) =>
  req(method, `${BINANCE_URL}${path}`, data, false, false, false, true);

export const requestMg = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${MG_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestT1 = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${T1_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestEvo = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${EVO_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestPp = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${PP_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestPg = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${PG_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestYgg = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${YGG_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestEvoplay = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${EVOPLAY_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestJili = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${JILI_SLOT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestSpribe = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${SPRIBE_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestPT = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${PT_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestPPLive = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${PPLIVE_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestFC = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${ONEPLAY_MAIN_API}${path}`, data, needAuth, needCurrency);
export const requestCQ9 = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${ONEPLAY_MAIN_API}${path}`, data, needAuth, needCurrency);
export const requestPlayStar = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${PLAYSTAR_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestTcg = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${TCG_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestWeblockchain = (
  method,
  path,
  data = null,
  needAuth = false,
  needCurrency = true,
) => req(method, `${PROTOCOL}://${WEBLOCKCHAIN_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestWelive = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${WELIVE_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestWelottery = (
  method,
  path,
  data = null,
  needAuth = false,
  needCurrency = true,
) => req(method, `${PROTOCOL}://${WELOTTERY_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestYoubet = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${YOUBET_MAIN_API}${path}`, data, needAuth, needCurrency);

export const requestAesexy = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${AESEXY_MAIN_API}${path}`, data, needAuth, needCurrency);

export const request77 = (method, path, data = null, needAuth = false, needCurrency = true) =>
  req(method, `${PROTOCOL}://${DOUBLE_SEVEN_MAIN_API}${path}`, data, needAuth, needCurrency);
