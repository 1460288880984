import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CustomModal } from "../components";
import localStorageKey from "../enumerations/localStorageKey";
import { useGetSetting } from "../hooks/download/appDownload.hook";
import { copyText } from "../plugins/utils";
import { useLogin } from "./LoginContext";

const AppContext = createContext({
  isApp: false,
  isRWD: false,
  latestVer: undefined,
  currentVer: undefined,
});

export const AppContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { data: setting } = useGetSetting({});
  const { openCSDrawer } = useLogin();
  const [isApp, setIsApp] = useState(false);
  const [isRWD, setIsRWD] = useState(false);
  const [showDownloadAppPopup, setShowDownloadAppPopup] = useState(false);
  const [forceDownload, setForceDownload] = useState(false);
  const [firstDownload, setFirstDownload] = useState(false);
  const [latestVer, setLatestVer] = useState(undefined);
  const [currentVer, setCurrentVer] = useState(undefined);
  const [params] = useSearchParams();

  const onDownloadApp = () => {
    if (firstDownload) {
      openCSDrawer();
      return;
    }
    if (setting) {
      window.open(setting?.android_download_link, "_blank");
    }
  };

  const onCancelDownload = () => {
    if (!forceDownload || firstDownload) {
      setShowDownloadAppPopup(false);
      return;
    }
    openCSDrawer();
  };

  const handleCopy = (link) => {
    copyText(link, t("normal.copy.complete"));
  };

  const renderContent = () => {
    if (firstDownload)
      return (
        <div>
          {t("download.force.content")}
          <br />
          <div
            style={{ color: "var(--ant-primary-color)", textDecoration: "underline" }}
            onClick={() => handleCopy(setting?.android_download_link)}
          >
            {t("download.link")}
          </div>
        </div>
      );
    if (forceDownload) return <div>{t("download.force.content")}</div>;
    return <div>{t("download.optional.content")}</div>;
  };

  const handleShowDownloadPopup = ({ force = false, first = false }) => {
    setForceDownload(force);
    setFirstDownload(first);
    setShowDownloadAppPopup(true);
  };

  const getMinorMajorVersion = ({ version = undefined }) => {
    if (version) {
      const _version = version.split(".");
      return [_version[0], _version[2]];
    }
    return undefined;
  };

  const getLatestAppVersion = () => {
    if (setting) {
      const appdownloadurl = setting?.android_download_link;
      console.debug("appdownload", appdownloadurl);
      const split = appdownloadurl.split("_");
      setLatestVer(split[split.length - 1].slice(0, -4));
      return split[split.length - 1].slice(0, -4);
    }
  };

  const compareVersion = useCallback(
    ({ current = undefined }) => {
      if (setting && setting?.android_download_link && current) {
        const latestVersion = getLatestAppVersion();
        setCurrentVer(current);
        localStorage.setItem(localStorageKey.CURRENT_APP_VERSION, current);
        const latestVer = getMinorMajorVersion({ version: latestVersion });
        const currentVer = getMinorMajorVersion({ version: current });
        if (latestVer && currentVer) {
          if (parseInt(currentVer[0]) < parseInt(latestVer[0])) {
            handleShowDownloadPopup({ force: true });
          } else {
            if (parseInt(currentVer[1]) < parseInt(latestVer[1])) {
              handleShowDownloadPopup({ force: false });
            }
          }
        }
      }
    },
    [setting],
  );

  const checkIsApp = () => {
    const isApp = localStorage.getItem(localStorageKey.IS_APP);

    if (
      params.get("app") ||
      isApp ||
      window.matchMedia("(display-mode: standalone)").matches === true
    ) {
      if (window.matchMedia("(display-mode: standalone)").matches === true) {
        setIsRWD(true);
      }
      if (!isApp) window.localStorage.setItem(localStorageKey.IS_APP, true);
      setIsApp(true);
      if (params.get("app")) {
        if (params.get("ver")) {
          compareVersion({ current: params.get("ver") });
        } else {
          getLatestAppVersion();
          handleShowDownloadPopup({ force: true, first: true });
        }
      }
    }
    return;
  };

  useEffect(() => {
    checkIsApp();
  }, [params, setting]);

  return (
    <AppContext.Provider value={{ isApp, isRWD, latestVer, currentVer }}>
      <>
        {children}
        <div id="copy-group" />
        <CustomModal
          isModalVisible={showDownloadAppPopup}
          setIsModalVisible={setShowDownloadAppPopup}
          title={t("download.title")}
          content={renderContent()}
          needIcon={true}
          okBtnText={firstDownload ? t("home.cs") : t("download.now")}
          cancelBtnText={!forceDownload || firstDownload ? t("download.later") : t("home.cs")}
          onOk={onDownloadApp}
          onCancel={onCancelDownload}
        />
      </>
    </AppContext.Provider>
  );
};
export const useApp = () => {
  const AppValue = useContext(AppContext);
  return AppValue;
};
