import { requestWin } from "../../apis";
import localStorageKey from "../../enumerations/localStorageKey";

const CURRENCY = window.localStorage.getItem(localStorageKey.DEFAULT_CURRENCY);

export const apiPostGameBet = (params) => requestWin("post", `/${CURRENCY}/game/bet`, params, true);

export const getGameWay = ({ params }) =>
  requestWin("get", `/${CURRENCY}/game/way/${params.game_way}`, params, true);

export const getGameMethod = ({ params }) =>
  requestWin("get", `/${CURRENCY}/game/way/method/${params.game_way_type}`, params, true);

export const getGameRecord = ({ params }) =>
  requestWin("get", `/${CURRENCY}/game/record`, params, true);

export const getGameRule = ({ params }) =>
  requestWin("get", `/${CURRENCY}/game/rule`, params, true);