import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "..";
import { useLoading } from "../../context/modules/LoadingContext";

function CustomerServiceDrawer({ csLink = "", visible = false, handleVisible = () => {} }) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  const renderDrawerContent = () => {
    return (
      <div style={{ padding: 0, height: "100%" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          seamless="seamless"
          frameBorder={0}
          src={csLink}
          title="Customer Service"
          onLoad={() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }}
          onError={(e) => {
            console.debug(e);
            setIsLoading(false);
          }}
        />
      </div>
    );
  };
  useEffect(() => {
    if (visible === true) {
      setIsLoading(true);
    }
    return () => {
      setIsLoading(false);
    };
  }, [visible]);

  return (
    <Drawer
      placement={"right"}
      showDrawer={visible}
      setShowDrawer={handleVisible}
      title={t("normal.customerService")}
      height={100}
      onClose={handleVisible}
      renderContent={renderDrawerContent}
      closeIcon={false}
      destroyOnClose={true}
      style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
    />
  );
}

export default CustomerServiceDrawer;
