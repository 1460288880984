import { RightOutlined } from "@ant-design/icons";
import { Card, Drawer } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { ACCOUNT_VERIFY_METHOD } from "../../config.js";
import { useLogin } from "../../context/LoginContext.js";
import { AppBar } from "../index.js";
function ConfirmValidatePopup({
  visible,
  setVisible,
  onMobileClick = () => {},
  onEmailClick = () => {},
  hideEmail,
  hideMobile,
}) {
  const { openCSDrawer } = useLogin();
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const cardStyle = {
    marginTop: 8,
    borderRadius: 8,
    boxShadow: "1px 8px 12px 0 #e2eaf0",
    backgroundImage:
      "linear-gradient(to bottom, var(--color-table-normal-1), var(--color-login-input-text-title) 100%)",
  };
  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: "0" }}
        width={"100%"}
        destroyOnClose={true}
        style={{ maxWidth: "var(--maxWidth-in-pc)", margin: "0 auto" }}
      >
        <AppBar
          title={t("normal.selectValidate")}
          onClickEvent={() => {
            setVisible(false);
          }}
        />
        <div style={{ padding: 16 }}>
          {hideMobile || ACCOUNT_VERIFY_METHOD.includes("phone") === false ? (
            <></>
          ) : (
            <Card style={cardStyle} bodyStyle={{ display: "flex" }} onClick={onMobileClick}>
              <div>{t("normal.mobileValidate")}</div>
              <div style={{ flex: 1 }} />
              <div>
                <RightOutlined />
              </div>
            </Card>
          )}
          {hideEmail || ACCOUNT_VERIFY_METHOD.includes("email") === false ? (
            <></>
          ) : (
            <Card style={cardStyle} bodyStyle={{ display: "flex" }} onClick={onEmailClick}>
              <div> {t("normal.emailValidate")} </div>
              <div style={{ flex: 1 }} />
              <div>
                <RightOutlined />
              </div>
            </Card>
          )}
          <Card
            style={cardStyle}
            bodyStyle={{ display: "flex" }}
            onClick={() => {
              openCSDrawer();
            }}
          >
            <div> {t("signup.contactCs")} </div>
            <div style={{ flex: 1 }} />
            <div>
              <RightOutlined />
            </div>
          </Card>
        </div>
      </Drawer>
    </>
  );
}

export default ConfirmValidatePopup;
