import { useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetCustomerService, apiGetCustomerServiceNoCurrency } from "./service";

export const useGetCustomerService = (options) => {
  return useQuery([queryKey.CS_LINK], () => apiGetCustomerService().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetAllCustomerService = (options) => {
  return useQuery(
    [queryKey.CS_LINK_NO_CURRENCY],
    () => apiGetCustomerServiceNoCurrency().then((res) => res.data.data),
    {
      ...options,
    },
  );
};
